import chroma from 'chroma-js';

export const websiteBg = '#f8f8f8';

export const bgcolor = '#004b6c';
export const bgcolor2 = '#f8f8f8';

export const primary0 = chroma(bgcolor).brighten(0.4).hex();
export const primary = chroma(bgcolor).brighten(0.7).hex();
export const primary2 = chroma(bgcolor).brighten(1.0).hex();
export const primary3 = chroma(bgcolor).brighten(1.3).hex();

export const accent1 = chroma(bgcolor).brighten(0.5).hex();

export const secondary = '#f00';
export const secondaryA = '#028090';
export const secondaryB = '#00bfb2';
export const secondaryC = '#00a878';
export const secondaryD = '#ee6c4d';
export const secondaryE = '#f7cea0';
export const secondaryF = '#ff9b71';
export const secondaryG = '#586978';

export const accent = '#B41D0A';
export const success = '#43a047';
export const failure = '#f44336';
export const disabledFg = '#00000042';
export const disabledBg = '#0000001f';
export const button = '#7283bb';
export const text = '#000000';
export const checkbox = '#7283bb';

// DEPRECATED
export const green = '#1c3';
export const red = '#f00';
