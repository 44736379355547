import React, { useState } from 'react';
import { QUICK_REPORT_INTERVALS, REPORT_OPTIONS } from '../../constants';
import {
	Accordion,
	AccordionSummary,
	Avatar,
	Button,
	ButtonGroup,
	Fab,
	LinearProgress,
	Menu,
	MenuItem,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { ArrowDropDown, GetApp as Download, Email, FlashOn, LocationCity, Layers, SettingsRemote, Error } from '@material-ui/icons';
import { Document, Page } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import ReportHeader from '../ReportHeader';
import * as colors from '../../colors';
import { withStyles } from '@material-ui/styles';
import SelectionTable from '../SelectionTable';
import WizardAlert from './WizardAlert';
import axios from 'axios';
import { format as formatDate, addHours, set } from 'date-fns';
import { addInterval } from '../../utility-functions/intervalToDate';
import { QUERY_PROPERTIES, QUERY_SENSORDATA_VIEW_SHORT } from '../../utility-functions/gqlQueries';
import { useQuery } from '@apollo/client';

const PDF_COLOR = '#eb0112';
const PDF_COLOR_DARKER = '#b2000e';

const REPORT_PROVIDER_URL = process.env.REACT_APP_REPORT_PROVIDER_URL;

const reportStyle = { width: '545px', widthNumber: 545 };

const SettingsAccordion = withStyles({
	root: {
		border: '1px solid rgba(0, 0, 0, .125)',
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(Accordion);

const SettingsSummary = withStyles({
	root: {
		marginBottom: -1,
		minHeight: '1rem',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, .03)',
		},
		'&$expanded': {
			minHeight: '1rem',
			backgroundColor: 'rgba(0, 0, 0, .03)',
			borderBottom: '2px solid rgba(0, 0, 0, .125)',
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(AccordionSummary);

const useStyles = makeStyles(theme => ({
	root: {
		margin: '0 1.5rem 1.5rem 1.5rem',
		display: 'flex',
	},
	leftSide: {
		// border: '1px solid black',
		width: '70%',
		height: '100%',
	},
	rightSide: {
		margin: '0 0 0 2rem',
		justifyContent: 'end',
		// border: '1px solid black',
		height: '100%',
	},
	buttonGroup: {
		margin: '1rem 0 0 0',
		'& button': {
			color: PDF_COLOR,
			//color: 'white',
			backgroundColor: PDF_COLOR,
			//borderColor: PDF_COLOR,
			fontWeight: 'bold',
			'&:hover': {
				color: 'white',
				backgroundColor: PDF_COLOR_DARKER,
			},
			// margin: '1rem 0.5rem 0 0',
			// padding: '0.5rem 1rem',
		},
		// color: PDF_COLOR,
		// //borderColor: PDF_COLOR,
		// backgroundColor: PDF_COLOR,
	},
	generateButton: {
		color: theme.palette.secondary.main,
		// '-webkit-text-fill-color': 'white',
		// '-webkit-text-stroke': '1px',
		// borderColor: PDF_COLOR,
		backgroundColor: theme.palette.secondary.main,
		fontWeight: 'bold',
		padding: '0.5rem 1rem',
		'&:hover': {
			// color: 'white',
			backgroundColor: 'darkorange',
		},
	},
	subscribeButton: {
		color: 'white',
		// //borderColor: PDF_COLOR,
		backgroundColor: PDF_COLOR,
		'&:hover': {
			backgroundColor: PDF_COLOR_DARKER,
		},
	},
	cancelButton: {
		color: PDF_COLOR,
		borderColor: PDF_COLOR,
		padding: '0.3rem 2rem',
		'&:hover': {
			color: 'white',
			backgroundColor: PDF_COLOR_DARKER,
		},
	},
	reportTitle: {
		textTransform: 'none',
		fontSize: '2rem',
		fontWeight: 'normal',
		margin: '0 0 0 0',
		padding: '0 0.5rem 0 0.5rem',
	},
}));

const ICON_PROPS = { width: '1.4rem' };

function ReportSettings({ title, expanded, handleExpand, icon, children }) {
	return (
		<SettingsAccordion expanded={expanded} onChange={handleExpand}>
			<SettingsSummary expandIcon={<ArrowDropDown />} aria-controls='panel1bh-content' id='panel1bh-header'>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Avatar style={{ backgroundColor: colors.primary, color: 'white', width: '2rem', height: '2rem' }}>{icon}</Avatar>
					<Typography style={{ fontSize: '1.2rem', marginLeft: '0.4rem', fontWeight: 500 }}>{title}</Typography>
				</div>
			</SettingsSummary>
			{children}
		</SettingsAccordion>
	);
}

const dropDownWidth = `calc(90vw - ${reportStyle.width} - 6rem)`;

export default function CreatePdfReport({ setShouldSave, handleClose, handleSave, loadReport, selectedData, ...props }) {
	const classes = useStyles();
	const [pageCount, setPageCount] = useState(1);
	const [selectedInterval, setSelectedInterval] = useState(QUICK_REPORT_INTERVALS.week);
	const [intervalAnchorEl, setIntervalAnchorEl] = useState(null);
	const [expanded, setExpanded] = useState('pages');
	const [instantReportDate, setInstantReportDate] = useState({ start: '', end: '' });
	const [expandedLoaded, setExpandedLoaded] = useState([]);
	const [openDialog, setOpenDialog] = useState(false);


	const { data: propertyData, loading: propertyLoading } = useQuery(QUERY_PROPERTIES, {
		skip: !expandedLoaded.includes('properties'),
	});

	const handleButtonClick = () => {
		setShouldSave(true);

		if (reportProps.pages.length === 0) {
			return setAlertMessage(t('reportPopups.noPageSelectedError'));
		}
		if (reportProps.properties.length === 0) {
			return setAlertMessage(t('reportPopups.noPropertySelectedError'));
		}
		setOpenDialog(true);
		//handleSave({ ...reportProps, sensors: reportProps.sensors.map(sen => parseInt(sen)) });
	};
	const handleCloseDialog = () => {
		setShouldSave(false);
		setOpenDialog(false);
	};

	const handleConfirmSave = () => {
		handleSave({ ...reportProps, sensors: reportProps.sensors.map(sen => parseInt(sen)) });
		setOpenDialog(false);
	};

	const { data: sensorData, loading: sensorLoading } = useQuery(QUERY_SENSORDATA_VIEW_SHORT, {
		skip: !expandedLoaded.includes('sensors'),
		variables: {
			locationids: props.userInfo?.locationids,
		},
	});

	const [pdfLoading, setPdfLoading] = useState(false);
	const [pdfFailed, setPdfFailed] = useState(false);
	const [cancelPdfDownload, setCancelPdfDownload] = useState(() => () => { });
	const [pdfFile, setPdfFile] = useState();
	const [reportProps, setReportProps] = useState({
		pages: loadReport?.pages || [],
		properties: loadReport?.properties || [],
		sensors: loadReport?.sensors.map(sen => `${sen}`) || [],
	});
	const reportPropsLength = loadReport?.properties.length || 0;
	const [alert, setAlert] = useState({ message: '', severity: 'error', open: false });
	const { t } = useTranslation();

	const setAlertMessage = (message, severity = 'error') => {
		setAlert({ message, severity, open: true });
	};

	const resetAlert = () => {
		setAlert({ ...alert, open: false });
	};

	const handleExpand = type => {
		if (expanded === type) {
			return setExpanded('');
		}
		setExpanded(type);
		setExpandedLoaded([...new Set([...expandedLoaded, type])]);
	};

	const handleSubscribe = () => {
		setShouldSave(false);
		if (reportProps.pages.length === 0) {
			return setAlertMessage(t('reportPopups.noPageSelectedError'));
		}
		if (reportProps.properties.length === 0) {
			return setAlertMessage(t('reportPopups.noPropertySelectedError'));
		}
		handleSave({ ...reportProps, sensors: reportProps.sensors.map(sen => parseInt(sen)) });
	};

	const handleSaveInterval = interval => {
		setIntervalAnchorEl(null);
		setSelectedInterval(interval);
	};

	const startPdfGeneration = () => {
		cancelPdfDownload();
		setPdfLoading(true);
		setPdfFailed(false);
		const startOfToday = addInterval(new Date(), 'today', true);
		const startOfPeriod = addInterval(startOfToday, selectedInterval.id, false, -1);
		const startStr = addHours(startOfPeriod, 2).toISOString().slice(0, 10);
		const endStr = addHours(startOfToday, 2).toISOString().slice(0, 10);
		setInstantReportDate({
			start: startOfPeriod,
			end: startOfToday,
		});

		axios
			.post(
				REPORT_PROVIDER_URL + '/reports/pdf',
				{
					start: startStr,
					end: endStr,
					...reportProps,
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: props.userInfo?.accessToken,
					},
					responseType: 'arraybuffer',
					cancelToken: new axios.CancelToken(cancel => setCancelPdfDownload(() => cancel)),
				}
			)
			.then(res => {
				setPdfFile(res.data);
				setPdfLoading(false);
				setPageCount(1);
			})
			.catch(err => {
				if (!axios.isCancel(err)) {
					setPdfFailed(true);
				}
			});
	};

	const intervalOpen = Boolean(intervalAnchorEl);

	return (
		<>
			<WizardAlert
				open={alert.open}
				severity={alert.severity}
				onClose={resetAlert}
				style={{
					margin: '1rem 1rem 0 1rem',
					width: 'calc(100% - 2rem)',
				}}
				duration={8000}
			>
				{alert.message}
			</WizardAlert>
			<div className={classes.root}>
				<div className={classes.leftSide}>
					<h2 style={{ padding: 0, margin: '0.5rem 0 0 0.4rem' }}>{selectedData?.reportname ? selectedData.reportname : t('reportPopups.reportSettingsTitle')}</h2>
					<hr />
					<ReportSettings
						title={t('reportPopups.pagesTitle') + ': ' + reportProps.pages.length}
						expanded={expanded === 'pages'}
						handleExpand={() => handleExpand('pages')}
						icon={<Layers style={ICON_PROPS} />}
					>
						<div style={{ maxWidth: dropDownWidth }}>
							<SelectionTable
								columns={[{ title: t('reports.page'), field: 'name' }]}
								data={Object.values(REPORT_OPTIONS)}
								preselectedIds={loadReport?.pages || []}
								maxColumnLength={28}
								toolbarHeight='3.3rem'
								style={{ boxShadow: 'none', overflow: 'hidden' }}
								onSelectionChange={selected => setReportProps({ ...reportProps, pages: selected.map(sel => sel.id) })}
								pageSizeOptions={[10, 15, 20]}
								tableProps={{
									maxColumnLength: 32,
									options: {
										showTextRowsSelected: false,
										maxBodyHeight: 'calc(100vh - 25rem)',
										toolbar: false,
									},
								}}
							/>
						</div>
					</ReportSettings>
					<ReportSettings
						title={t('reportPopups.propertiesTitle') + ': ' + (reportProps.properties.length || 0 /* t('generic.all') */)}
						expanded={expanded === 'properties'}
						handleExpand={() => handleExpand('properties')}
						icon={<LocationCity style={ICON_PROPS} />}
					>
						{propertyData ? (
							<div style={{ maxWidth: dropDownWidth }}>
								<SelectionTable
									localization={{
										title: (
											<div style={{ fontSize: '1.1rem', padding: '0' }}>{t('reports.selectPropertiesReport')}</div>
										),
									}}
									data={propertyData?.getSensorLocations || []}
									dataId='locationid'
									preselectedIds={loadReport?.properties || []}
									columns={[
										{ title: t('generic.address'), field: 'street' },
										{ title: t('generic.city'), field: 'city' },
									]}
									pageSizeOptions={[10, 15, 20]}
									onSelectionChange={rows =>
										setReportProps({ ...reportProps, properties: rows.map(row => row.locationid) })
									}
									style={{ boxShadow: 'none' }}
									tableProps={{
										toolbarHeight: '3rem',
										maxColumnLength: 32,
										options: {
											showTextRowsSelected: false,
											maxBodyHeight: 'calc(100vh - 31rem)',
										},
									}}
								/>
							</div>
						) : (
							<LinearProgress />
						)}
					</ReportSettings>
					<ReportSettings
						title={t('reportPopups.sensorsTitle') + ': ' + reportProps.sensors.length}
						expanded={expanded === 'sensors'}
						handleExpand={() => handleExpand('sensors')}
						icon={<SettingsRemote style={ICON_PROPS} />}
					>
						{sensorData ? (
							<div style={{ maxWidth: dropDownWidth }}>
								<SelectionTable
									localization={{
										title: <div style={{ fontSize: '1.1rem', padding: '0' }}>{t('reports.selectSensorsReport')}</div>,
									}}
									data={sensorData?.getSensorView || []}
									dataId='sensorid'
									preselectedIds={loadReport?.sensors.map(sen => `${sen}`) || []}
									columns={[
										{ title: t('generic.name'), field: 'name' },
										{ title: t('reports.group'), field: 'groupname' },
										{ title: t('generic.address'), field: 'street' },
										{ title: t('generic.city'), field: 'city' },
									]}
									pageSizeOptions={[10, 15, 20]}
									onSelectionChange={rows => setReportProps({ ...reportProps, sensors: rows.map(row => row.sensorid) })}
									style={{ boxShadow: 'none' }}
									tableProps={{
										maxColumnLength: 32,
										toolbarHeight: '3rem',
										options: {
											showTextRowsSelected: false,
											maxBodyHeight: 'calc(100vh - 31rem)',
											headerStyle: {
												height: '2rem',
											},
										},
									}}
								/>
							</div>
						) : (
							<LinearProgress />
						)}
					</ReportSettings>
				</div>
				<div className={classes.rightSide}>
					<div style={{ display: 'flex', margin: '0 0 0 0', justifyContent: 'space-between' }}>
						<ButtonGroup>
							<Button
								//onClick={startPdfGeneration}
								onClick={() => {
									if (reportProps.properties.length === 0) {
										return setAlertMessage(t('reportPopups.noPropertySelectedError'));
									}
									startPdfGeneration();
								}}
								startIcon={<FlashOn />}
								variant='contained'
								color='secondary'
							//className={classes.generateButton}
							>
								{t('reportPopups.quickGenerateButton')}
							</Button>
							<Button
								id='basic-button'
								aria-controls={intervalOpen ? 'basic-menu' : undefined}
								aria-haspopup='true'
								aria-expanded={intervalOpen ? 'true' : undefined}
								endIcon={<ArrowDropDown />}
								variant='contained'
								color='secondary'
								//className={classes.generateButton}
								style={{ padding: '0 0.5rem 0 0.5rem' }}
								onClick={event => setIntervalAnchorEl(event.currentTarget)}
							>
								{selectedInterval.label}
							</Button>
						</ButtonGroup>
						<Menu
							id='basic-menu'
							anchorEl={intervalAnchorEl}
							open={intervalOpen}
							onClose={() => setIntervalAnchorEl(null)}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
							}}
							getContentAnchorEl={null}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							{Object.values(QUICK_REPORT_INTERVALS).map(interval => (
								<MenuItem key={interval.label} onClick={() => handleSaveInterval(interval)}>
									{interval.label}
								</MenuItem>
							))}
						</Menu>
						{/* 	{reportProps.properties.length>0 &&(<Button
							startIcon={<Email />}
							variant='contained'
							// className={classes.subscribeButton}
							color='primary'
							style={{ marginLeft: '0.5rem' }}
							onClick={() => handleSubscribe()}
						>
							{t('reportPopups.subscribeButton')}
						</Button>)} */}
					</div>

					<div
						style={{
							position: 'absolute',
							left: '15%',
							zIndex: '10',
							marginTop: '1.5rem',
						}}
					></div>
					{!pdfFile ? (
						<Button
							style={{
								backgroundColor: '#2C3E75',
								color: 'white',
								padding: '1rem 2rem',
								fontSize: '1.3rem',
								border: 'none',
								cursor: 'auto',
								borderRadius: '5px',
								boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
								textTransform: 'none',
								position: 'absolute',
								filter: pdfLoading ? 'opacity(0.5) blur(0.1rem)' : 'opacity(0.5)',
								margin: '15rem 0 0 11.3rem',
								zIndex: 1,
							}}
						>
							{t('constants.exampleReport')}
						</Button>
					) : undefined}
					{pdfFile && !pdfLoading ? (
						<Fab
							variant='extended'
							disabled={pdfFailed}
							onClick={() => {
								const link = document.createElement('a');
								link.href = URL.createObjectURL(new Blob([pdfFile]));
								link.download =
									//'rapport-' +
									t('generic.report') +
									formatDate(instantReportDate.start, 'yyyyMMdd') +
									(instantReportDate.start.toISOString().startsWith(instantReportDate.end.toISOString().slice(0, 10))
										? ''
										: formatDate(instantReportDate.end, '-yyyyMMdd')) +
									'.pdf';
								link.dispatchEvent(new MouseEvent('click'));
							}}
							style={{
								height: '2.6rem',
								color: colors.primary,
								width: '6rem',
								marginTop: '0.3rem',
								marginLeft: '0.2rem',
								position: 'absolute',
								zIndex: 1,
							}}
						>
							<Download style={{ marginRight: '0.5rem' }} />
							{'PDF'}
						</Fab>
					) : undefined}
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							position: 'absolute',
							zIndex: 1,
							alignItems: 'center',
							top: '10rem',
							marginLeft: '9rem',
						}}
					>
						{pdfLoading || pdfFailed ? (
							<div
								style={{
									width: 'max-content',
									background: '#fff',
									padding: '1rem',
									borderRadius: '0.3rem',
									boxShadow: '0rem 0.1rem 0.5rem #0008',
								}}
							>
								{pdfFailed ? (
									<div style={{ display: 'flex' }}>
										<Error style={{ color: '#f00' }} />
										<div style={{ margin: 'auto auto auto 0.6rem', fontSize: '112%', fontWeight: '300' }}>
											{t('reports.failedMsg')}
										</div>
									</div>
								) : (
									<>
										<div
											style={{
												margin: '-0.4rem 0 0.4rem',
												fontSize: '112%',
												fontWeight: '300',
												textAlign: 'center',
											}}
										>
											{t('reports.loadingMsg')}
										</div>
										<LinearProgress style={{ width: '16rem', height: '0.5rem', borderRadius: '0.2rem' }} />
									</>
								)}
							</div>
						) : undefined}
					</div>
					<div
						style={{
							height: 'calc(100vh - 16rem)',
							margin: '0.5rem 0 0 0',
							background: '#fff',
							overflowY: 'scroll',
							filter: pdfLoading
								? 'blur(0.1rem) opacity(0.8)' // While "generating report"
								: pdfFile
									? undefined // A report has been generated
									: 'blur(0.03rem) opacity(0.8)', // Showing the preview pdf
						}}
					>
						{!pdfFile ? <ReportHeader {...{ customer: props.userInfo?.customerName || 'meliox' }} /> : undefined}
						<Document
							file={pdfFile || require('../../assets/reports/exampleReportBody2.pdf').default}
							loading={<div style={{ width: reportStyle.width }} />}
							noData={<div style={{ width: reportStyle.width }} />}
							error={
								<div style={{ width: reportStyle.width, paddingTop: '2rem', textAlign: 'center', fontWeight: '500' }}>
									{t('reports.errorOccurred')}
									<br />
									{t('reports.couldNotGenerate')}
								</div>
							}
							onLoadSuccess={({ numPages }) => setPageCount(numPages)}
						>
							{Array.from(new Array(pageCount), (_, i) => (
								<Page key={`page_${i + 1}`} pageNumber={i + 1} width={reportStyle.widthNumber} />
							))}
						</Document>
					</div>
					<div style={{ display: 'flex', float: 'right', margin: '0.5rem 0 0 0' }}>
						<Button variant='outlined' className={classes.cancelButton} onClick={handleClose}>
							{t('generic.cancel')}
						</Button>
						{reportPropsLength > 0 && (<Button
							variant='contained'
							color='primary'
							style={{ marginLeft: '0.5rem' }}
							//onClick={() => handleSave(reportProps)}
							onClick={handleButtonClick}
						>
							{t('reports.saveReport')}
						</Button>)}
						<Dialog
							open={openDialog}
							onClose={handleCloseDialog}
							aria-labelledby='alert-dialog-title'
							aria-describedby='alert-dialog-description'
						>
							<DialogTitle id='alert-dialog-title'>{t('reports.confirmSaveTitle')}</DialogTitle>
							<DialogContent>
								<DialogContentText id='alert-dialog-description'>
									{t('reports.confirmSaveMessage')}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleCloseDialog} color='primary'>
									{t('generic.cancel')}
								</Button>
								<Button onClick={handleConfirmSave} color='primary' autoFocus>
									{t('generic.confirm')}
								</Button>
							</DialogActions>
						</Dialog>
						<Button
							startIcon={<Email />}
							variant='contained'
							// className={classes.subscribeButton}
							color='primary'
							style={{ marginLeft: '0.5rem' }}
							onClick={() => handleSubscribe()}
						>
							{t('reportPopups.subscribeButton')}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}
